import { template as template_1ede4cf5d4d3448ab6eed738ea4adca4 } from "@ember/template-compiler";
const FKText = template_1ede4cf5d4d3448ab6eed738ea4adca4(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
