import { template as template_859ca2fc5e9f44eaa6df6798281b989b } from "@ember/template-compiler";
const FKControlMenuContainer = template_859ca2fc5e9f44eaa6df6798281b989b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
