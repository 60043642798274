import { template as template_7b5ff420043744178649190af8b58fa1 } from "@ember/template-compiler";
const WelcomeHeader = template_7b5ff420043744178649190af8b58fa1(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
